<script lang="ts">
    import type { Snippet } from 'svelte'
    import type { HTMLAttributes } from 'svelte/elements'

    interface Props extends HTMLAttributes<HTMLLabelElement> {
        for: string
        children: Snippet
    }

    const {
        class: classNames = '',
        for: forEl,
        children,
        ...rest
    }: Props = $props()
</script>

<label class="label {classNames} " for={forEl} {...rest}>
    {@render children()}
</label>

<style global lang="postcss">
    .label {
        display: block;
    }
</style>
