<script lang="ts">
    import type { Snippet } from 'svelte'
    import type { HTMLAttributes } from 'svelte/elements'

    import type { AlertVariants } from '../types'
    import { concatClasses } from '../utils'

    interface Props extends HTMLAttributes<HTMLDivElement> {
        variant?: AlertVariants
        children: Snippet
    }

    const {
        class: classNames = '',
        variant = 'danger',
        children,
        ...rest
    }: Props = $props()

    const classes = $derived(
        concatClasses(['alert', `alert-${variant}`, classNames])
    )
</script>

<div class={classes} role="alert" {...rest}>
    {@render children()}
</div>

<style global lang="postcss">
    .alert {
        padding: theme('spacing.4');
        border-radius: theme('borderRadius.DEFAULT');
    }

    .alert-danger {
        color: theme('colors.red.700');
        background-color: theme('colors.red.50');
    }

    .alert-success {
        color: theme('colors.green.700');
        background-color: theme('colors.green.50');
    }

    .alert-warning {
        color: theme('colors.yellow.700');
        background-color: theme('colors.yellow.50');
    }

    .alert-info {
        color: theme('colors.slate.600');
        background-color: theme('colors.blue.50');
    }
</style>
