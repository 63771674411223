<script lang="ts">
    import type { Snippet } from 'svelte'
    import type { HTMLAttributes } from 'svelte/elements'

    import { concatClasses } from '../utils'

    interface Props extends HTMLAttributes<HTMLDivElement> {
        invalid?: boolean
        valid?: boolean
        children: Snippet
    }

    const {
        class: className = '',
        invalid = false,
        valid = false,
        children,
        ...rest
    }: Props = $props()

    const classes = $derived(
        concatClasses([
            'feedback',
            valid ? 'valid' : '',
            invalid ? 'invalid' : '',
            className,
        ])
    )
</script>

<div class={classes} {...rest}>
    {@render children()}
</div>

<style global lang="postcss">
    .feedback {
        margin-top: theme('spacing.1');
        font-size: theme('fontSize.sm');
    }

    .feedback.valid {
        color: theme('colors.green.800');
    }

    .feedback.invalid {
        color: theme('colors.red.800');
    }
</style>
